import { createStore } from 'vuex'

export default createStore({
  state: {
    // updateData(state,data){
    //   state.details = data
    // },
    JingGang:{
      longitude: 112.790734,
      latitude: 28.462833
    },
    TongGuang:{
      longitude: 112.850216,
      latitude: 28.45768
    },
    QiaoKou:{
      longitude: 112.732020,
      latitude: 28.503680
    },
    XingKang:{
      longitude: 112.801979,
      latitude: 28.394831
    },
    ShuTangShan:{
      longitude: 112.885871,
      latitude: 28.3961
    },
    jianzhuxiang:{
      num:0
    }
  },
  mutations: {
    changeNav(state,step) {
      state.PersonalCenterType = step;
    },
  },
  actions: {
  },
  modules: {
  }
})
