import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import qs from 'qs'
import './assets/global.css'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
// Vue.prototype.$axios = axios;
import Axios from 'axios'
import cookies from 'vue-cookies'
// Axios.defaults.baseURL="http://service-beta.csladmin.shippingmax.net/api/v1/"
Axios.defaults.baseURL = "http://127.0.0.1/"




let app = createApp(App)
app.config.globalProperties.$http = Axios;  //this.$http.
app.config.globalProperties.$cookies = cookies;  //this.$cookies.
// 测试环境 
// app.config.globalProperties.$Schttp = 'http://ccsu.lzxx8848.com/api';//this.$Cehttp

// 生产环境
// app.config.globalProperties.$Schttp = 'https://xnlv.lzxx8848.com/api';//this.$Schttp

// uinapp生产环境
app.config.globalProperties.$Schttp = 'https://xnlv.lizxx.com/api';//this.$Schttp

app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')

// 在路由切换前执行清理操作
router.beforeEach((to, from, next) => {
    // 在这里执行线程清理操作或资源释放逻辑

    // 取消定时器
    clearInterval();
    // 继续导航
    next();
});

