<template>
    <div class="BigBox">
        <!-- 导航 -->
        <div class="NavBox">
            <div class="LieBiao" @mouseenter="YiRuLieBiao" @mouseleave="YiChuLieBiao"></div>
            <div class="SouSBox">
                <div>
                    <input type="text" placeholder="搜索景区">
                </div>
                <div>
                    <img src="../../assets/HomePage/HomePageTwo/SouSuo.png" alt="">
                </div>
            </div>
            <div class="Title">{{Title}}</div>
            <div style="font-size:4vh;margin-left: 22vh;">{{hourminutesecond}}</div>
            <div class="Time">
                <div style="font-size:1vh">{{week}}</div>
                <div style="font-size:1vh">{{yeramonthday}}</div>
            </div>
            <div class="Weather">
                <img src="../../assets/HomePage/HomePageTwo/Weather.png" alt="">
            </div>
            <div class="GeRen">
                <img src="../../assets/HomePage/HomePageTwo/GeRen.png" alt="">
            </div>
        </div>
        <!-- 主体 -->
        <!-- 底部导航 -->
        <div class="BottomNavBox">
            <div class="ModuleNavBoxTwo">
                <div>游览路线</div>
            </div>
            <div @click="Figure_CACI()" class="ModuleNavBox">
                <div>数字文创</div>
            </div>
            <div @click="GoChanWan" class="ModuleNavBox">
                <div>畅玩古镇</div>
            </div>
            <div @click="Figure_M()" class="ModuleNavBox">
                <div>数字博物馆</div>
            </div>
        </div>
        <!-- 内容 -->
        <div class="ContentBox">
            <div style="font-size:20px;margin-top: 10px;">八大碗</div>
            <div style="height:30%;margin: 5px 0;">
                <img style="height:100%" src="../../assets/VRVisit/JG_Mfms.png" alt="">
            </div>
            <div style="width:85%;text-indent:2em">
                靖港八大碗是中国八大菜系湘菜组成的重要地方菜，楚越文化的蕴育，一千多年的传承和发展已成为湖湘饮食文化的地方特色。
            </div>
        </div>
        <div class="ModuleBox">
            <div class="Module">
                <div style="display:flex;">
                    <img src="../../assets/HomePage/HomePageTwo/JingQuKongJian.png" alt="">
                    <div style="height: 100%;display: flex;align-items: center;">景区空间</div>
                </div>
                <div style="display:flex;">
                    <img src="../../assets/HomePage/HomePageTwo/TeSheJingDian.png" alt="">
                    <div style="height: 100%;display: flex;align-items: center;">特色景点</div>
                </div>
                <div style="display:flex;">
                    <img src="../../assets/HomePage/HomePageTwo/WenYiKongJian.png" alt="">
                    <div style="height: 100%;display: flex;align-items: center;">文遗空间</div>
                </div>
                <div style="display:flex;">
                    <img src="../../assets/HomePage/HomePageTwo/SuiXingGouWu.png" alt="">
                    <div style="height: 100%;display: flex;align-items: center;">随心购物</div>
                </div>
            </div>
        </div>
        <div class="JingGangMaoDian" @click="GoVRVisit" @mouseenter="YiRu" @mouseleave="YiChu">
            <img src="../../assets/HomePage/HomePageTwo/MaoDian.png" alt="">
        </div>
    </div>  
</template>

<script>
import $ from "jquery";
import { log } from 'console';
export default {
    data(){
        return{
            timer: null,
            // 年月日
            yeramonthday:'',
            // 时分秒
            hourminutesecond:'',
            // 周
            week:'',
            Title:"长沙段湘江古镇灵镜体验",
            screenWidth: null,
        } 
},
    components:{

    },
    created(){},
    mounted() {
        this.timer = setInterval(() => {
            var date = new Date();
            //年 getFullYear()：四位数字返回年份
            var year = date.getFullYear(); //getFullYear()代替getYear()
            //月 getMonth()：0 ~ 11
            var month = date.getMonth() + 1;
            //日 getDate()：(1 ~ 31)
            var day = date.getDate();
            //时 getHours()：(0 ~ 23)
            var hour = date.getHours();
            //分 getMinutes()： (0 ~ 59)
            var minute = date.getMinutes();
            //秒 getSeconds()：(0 ~ 59)
            var second = date.getSeconds();
            this.yeramonthday = year + '.' + month + '.' + day
            this.hourminutesecond = hour + '.' + minute + '.' + second
            // 获取周几
            var days = date.getDay();
                    switch(days) {
                        case 1:
                            this.week = '星期一';
                            break;
                        case 2:
                            this.week = '星期二';
                            break;
                        case 3:
                            this.week = '星期三';
                            break;
                        case 4:
                            this.week = '星期四';
                            break;
                        case 5:
                            this.week = '星期五';
                            break;
                        case 6:
                            this.week = '星期六';
                            break;
                        case 0:
                            this.week = '星期日';
                            break;
                    }
        }, 1000);
        //获取屏幕尺寸
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            //屏幕尺寸变化
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    methods:{
        GoChanWan(){
            alert('暂未开放')
        },
        Figure_M(){
            this.$router.push('/digtalMuseum')
        },
        YiRu(){
            $('.ContentBox').css('opacity','1')
        },
        YiChu(){
            $('.ContentBox').css('opacity','0')
        },
        Figure_CACI(){
            this.$router.push('/culturalCreation')
        },
        GoVRVisit(){
            this.$router.push('/VRVisit')
        },
        // 移入列表
        YiRuLieBiao(){
            $('.ModuleBox').css('opacity','1')
        },
        YiChuLieBiao(){
            $('.ModuleBox').css('opacity','0')
        }
    },
    watch: {
        screenWidth: function (n, o) {
            if (n <= 1100) {
                this.Title = '湘江古镇'
                if(n <=900){
                    this.Title = ''
                }
            } else {
                this.Title = '长沙段湘江古镇灵镜体验'
            }
            
        }
    }

}
</script>

<style lang="less" scoped>
*{
    padding: 0;
    margin: 0;
}
.BigBox{
    width: 100%;
    height: 100%;
    background-image: url(../../assets/HomePage/HomePageTwo/BoxBG.png);
    background-size: 100% 100%;
    .JingGangMaoDian{
        width: 10%;
        height: 10%;
        position: absolute;
        top:10%;
        left: 28%;
        cursor: pointer;
        img{
            width: 100%;
        }
        animation:JingGangMaoDian 2s infinite alternate;
    }
    @keyframes JingGangMaoDian{
			from{
				top:9%;
			}
			to{
				top: 11%;
			}
		}
    .NavBox{
        position:fixed;
        width: 100%;
        height: 6.5vh;
        display: flex;
        align-items: center;
        color: white;
        background: linear-gradient(90deg, #142845 0%, #113C80 51%, #132A4C 100%);
        z-index: 999;
        .LieBiao{
            width: 5vh;
            height: 60%;
            background-image: url(../../assets/HomePage/HomePageTwo/LieBiao.png);
            background-size: 100% 100%;
            margin-left: 15px;
            opacity:1
        }
        .SouSBox{
            width: 350px;
            height: 70%;
            margin-left: 10vh;
            background-color: rgb(14, 34, 66);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div{
                height: 60%;
                display: flex;
                align-items: center;
                input{
                    background:none;  	
                    outline:none;  	
                    border:none;
                    font-size: 2vh;
                    color: white;
                    margin-left: 10px;
                }
                img{
                    height: 100%;
                    margin-right: 15px;
                }
            }
        }
        .Title{
            width: 45%;
            font-size: 4vh;
            display: flex;
            justify-content: center;
            white-space:nowrap;
            overflow: hidden;
        }
        .Time{
            margin: 0 0.5vh;
            text-align:right;
        }
        .Weather{
            height: 55%;
            img{
                height: 100%;
            }
        }
        .GeRen{
            height: 50%;
            margin-left: 4vh;
            img{
                height: 100%;
            }
        }
    }
    .BottomNavBox{
        width: 25%;
        min-width: 550px;
        height: 40px;
        position:fixed;
        bottom: 50px;
        left: 36%;
        display: flex;
        justify-content: space-between;
        .ModuleNavBoxTwo{
            padding: 0 20px;
            height: 100%;
            background-image: url(../../assets/HomePage/HomePageTwo/ModuleNavBGTwo.png);
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            color: rgb(255,242,96);
            cursor: pointer;
        }
        .ModuleNavBox{
            padding: 0 20px;
            height: 100%;
            background-image: url(../../assets/HomePage/HomePageTwo/ModuleNavBG.png);
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            font-size: 20px;
            color: rgb(8,110,165);
            cursor: pointer;
        }
    }
    .ContentBox{
        width: 18%;
        height: 40%;
        position: absolute;
        left: 15px;
        bottom: 5%;
        background-image: url(../../assets/HomePage/HomePageTwo/ContentBG.png);
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: rgb(203,251,255);
        opacity: 0;
        overflow: hidden;
    }
    .ModuleBox{
        width: 14%;
        height: 42%;
        position: absolute;
        left: 15px;
        top: 12%;
        background-image: url(../../assets/HomePage/HomePageTwo/ModuleBoxBG.png);
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        color: rgb(203,251,255);
        font-size: 2.8vh;
        .Module{
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            div{
                height: 18%;
                img{
                width: 25%;
                height: 100%;
                margin: 0 10px;
            }
            }
        }
    }
}
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ffffff;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: #ffffff;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: #ffffff;
    opacity: 1;
  }
  input::-moz-placeholder { /* Firefox 19+ */
   color: #ffffff;
   opacity: 1;
 }
</style>