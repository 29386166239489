import { createRouter, createWebHashHistory } from "vue-router";
import Navigation from "../views/Navigation.vue";
import HomePage from "../components/HomePage/homePage.vue";
import Login from "../components/Login/Login.vue";
const routes = [
    // 红色文化馆
    {
        path: '/RedCultureCenter',
        name: 'RedCultureCenter',
        component: () => import("../components/RedCultureCenter/RedCultureCenter.vue"),
    },
    // 红色文化馆=》红色人物详情
    {
        path: '/RedFigureDetails',
        name: 'RedFigureDetails',
        component: () => import("../components/RedCultureCenter/RedFigureDetails.vue"),
    },
    // 红色人物提交
    {
        path: '/CharactersSubmit',
        name: '红色人物提交',
        component: () => import("../components/RedCultureCenter/CharactersSubmit/CharactersSubmit.vue"),
    },
    // 红色影像提交
    {
        path: '/TheRedImage',
        name: '红色影像提交',
        component: () => import("../components/RedCultureCenter/TheRedImage/TheRedImage.vue"),
    },
    // 历史脉络详情
    {
        path: '/more',
        name: 'more',
        component: () => import("../components/VideoLog/more/more.vue"),
    },
    // 景区空间 
    // 景区空间首页
    {
        path: '/scenicspace',
        name: 'scenicspace',
        component: () => import("../components/Scenicspace/Scenicspace.vue"),
    },
    // // 景区空间=>人文故事
    {
        path: '/RWGS',
        name: 'RWGS',
        component: () => import("../components/Scenicspace/RWGS.vue"),
    },
    // 景区空间=>民俗民风
    {
        path: '/MSMF',
        name: 'MSMF',
        component: () => import("../components/Scenicspace/MSMF.vue"),
    },
    // 景区空间=>非物质
    {
        path: '/FeiWuZhi',
        name: 'FeiWuZhi',
        component: () => import("../components/Scenicspace/FWZ.vue"),
    },
    // 建筑景观馆
    // 建筑景观馆首页
    {
        path: '/ConstructionLandscape',
        name: 'ConstructionLandscape',
        component: () => import("../components/ConstructionLandscape/ConstructionLandscape.vue"),
    },
    {
        path: '/ConstructionXiang',
        name: 'ConstructionXiang',
        component: () => import("../components/ConstructionLandscape/ConstructionXiang.vue"),
    },
    // 订单
    {
        path: '/order',
        name: '订单',
        component: () => import("../components/CulturalCreation/order/order.vue"),
    },
    {
        path: '/SanWeiVideo',
        name: '三维视频',
        component: () => import('../components/SanWeiGuZhen/SanWeiVideo/SanWeiVideo.vue')
    },
    // {
    //     path: '/Photography',
    //     name: '倾斜摄影',
    //     component: () => import('../components/SanWeiGuZhen/Photography/Photography.vue')
    // },
    {
        path: '/Building',
        name: '单体建筑',
        component: () => import('../components/SanWeiGuZhen/Building/Building.vue')
    },
    {
        path: "/jG_VideoLog",
        name: "影像志",
        component: () => import("../components/VideoLog/VideoLog.vue"),
    },
    //文创详情
    {
        path: "/CulturalBlock",
        name: "文创详情",
        component: () => import("../components/CulturalBlock/CulturalBlock.vue"),
    },
    //数字博物馆
    {
        path: "/MuseumHome",
        name: "数字博物馆",
        component: () => import("../components/DigitalMuseum/MuseumHome.vue"),
    },
    //数字博物馆
    {
        path: "/digtalMuseum",
        name: "铜关古镇",
        component: () => import("../components/DigitalMuseum/DigitalMuseum.vue"),
    },
    // VR游览
    {
        path: "/vrVisit",
        name: "VR游览",
        component: () => import("../components/VRVisit/VRVisit.vue"),
    },
    // 靖港详情主页
    {
        path: "/jG_ParticularsList",
        name: "jG_ParticularsList",
        component: () => import("../components/VRVisit/JG_ParticularsList.vue"),
    },
    // 靖港皮影
    {
        path: "/shadowPlay",
        name: "靖港皮影",
        component: () => import("../components/VRVisit/ShadowPlay.vue"),
    },
    // 历史建筑
    {
        path: "/historicBuildings",
        name: "历史建筑",
        component: () => import("../components/VRVisit/HistoricBuildings.vue"),
    },
    // 数字文创
    {
        path: "/culturalCreation",
        name: "数字文创",
        component: () => import("../components/CulturalCreation/CulturalCreation.vue"),
    },
    {
        path: "/GameList",
        name: "畅玩古镇",
        component: () => import("../components/GameList/index.vue"),
    },
    // 随心购物
    {
        path: "/ShopAtWillHome",
        name: "随心购物",
        component: () => import("../components/ShopAtWill/ShopAtWillHome.vue"),
    },
    {
        path: "/bazaar",
        name: "商品详情",
        component: () => import("../components/CulturalCreation/Bazaar.vue"),
    },
    {
        path: "/evaluate",
        name: "订单评价",
        component: () => import("../components/CulturalCreation/evaluate/evaluate.vue"),
    },
    // 三维古镇 evaluate
    {
        path: "/ancientTown",
        name: "三维古镇",
        component: () => import("../components/AncientTown/AncientTown.vue"),
    },
    // {
    //     path: "/qingxie",
    //     name: "倾斜摄影",
    //     component: () => import('../components/AncientTown/QingXie.vue')
    // },
    {
        path: "/video",
        name: "三维视频",
        component: () => import('../components/AncientTown/Video.vue')
    },
    {
        path: "/dantijianzhu",
        name: "单体建筑",
        component: () => import('../components/AncientTown/DanTiJianZhu.vue')
    },
    // 特色景点
    {
        path: "/SpecialScenic",
        name: "特色景点",
        component: () => import('../components/SpecialScenic/SpecialScenic.vue')
    },
    // 特色景点详情
    {
        path: "/ScenicDetails",
        name: "特色景点详情",
        component: () => import('../components/SpecialScenic/SpecialScenicXiang.vue')
    },
    // 文创馆首页
    {
        path: "/CulturalHomePage",
        name: "文创首页",
        component: () => import('../components/CultureLnnovation/CulturalHomePage.vue')
    },
    // 文创详情
    {
        path: "/CulturalDetail",
        name: "文创详情",
        component: () => import('../components/CultureLnnovation/CulturalDetail.vue')
    },
    // 非物质文化遗首页
    {
        path: "/IntangibleCultural",
        name: "非物质文化遗首页",
        component: () => import('../components/IntangibleCultural/IntangibleCultural.vue')
    },
    // 非遗征集
    {
        path: "/Collection",
        name: "非遗征集",
        component: () => import('../components/IntangibleCultural/Collection.vue')
    },
    // 个人中心
    {
        path: "/personalCenter",
        name: "个人中心",
        component: () => import("../components/PersonalCenter/PersonalCenter.vue"),
        children: [
            {
                path: "personalInformation",
                name: "个人信息",
                component: () => import("../components/PersonalCenter/PersonalInformation/PersonalInformation.vue")
            },
            {
                path: "myOrder",
                name: "我的订单",
                component: () => import("../components/PersonalCenter/MyOrder/MyOrder.vue")
            },
            {
                path: "shoppingCart",
                name: "购物车",
                component: () => import("../components/PersonalCenter/ShoppingCart/ShoppingCart.vue")
            },
            {
                path: "paymentHistory",
                name: "支付流水",
                component: () => import("../components/PersonalCenter/PaymentHistory/PaymentHistory.vue")
            },
            {
                path: "myAssessment",
                name: "我的评价",
                component: () => import("../components/PersonalCenter/MyAssessment/MyAssessment.vue")
            },
            {
                path: "workTable",
                name: "工作台",
                component: () => import("../components/PersonalCenter/WorkTable/WorkTable.vue")
            },
            {
                path: "collect",
                name: "征集管理",
                component: () => import("../components/PersonalCenter/collect/collect.vue")
            },
            {
                path: "colRest",
                name: "其他服务",
                component: () => import("../components/PersonalCenter/colRest/colRest.vue")
            },
        ],
    },
    //首页
    // {
    //   path: "/",
    //   component: HomePage,
    // },
    {
        path: "/homePage",
        name: "首页",
        component: () => import("../components/HomePage/homePage.vue"),
    },
    {
        path: "/",
        name: "首页",
        component: () => import("../components/HomePage/HomePageTwo.vue"),
    },
    //导航主键
    {
        path: "/navigation",
        component: Navigation,
    },
    // 登录注册
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/register",
        name: "登陆",
        component: () => import("../components/Login/Register.vue"),
    },
    // ObliquePhoto
    {
        path: "/ObliquePhoto",
        name: "倾斜摄影",
        component: () => import("../components/ObliquePhoto/index.vue"),
    }
    //畅玩古镇
    //   {
    //     path: "/PlayFreely",
    //     name: "畅玩古镇",
    //     component: () => import("../components/PlayFreely/PlayFreely.vue"),
    //   },
];

const router = createRouter({
    // 设置点击之后的样式
    linkActiveClass: "active",
    history: createWebHashHistory(),
    routes,
})

router.afterEach((to, from, next) => {

    window.scrollTo(0, 0)
})

//导航守卫
// router.beforeEach((to,from,next)=>{
//   if(to.path === "/login")
//     return next()
//   //获取token
//   const token=window.cookies.getItem("token")
//   if(token) //如果有票就允许访问
//     next()
//   else      //没票，就跳转到登录
//     return next("/login")
// })
//导出暴露路由
export default router;
