<template>
    <div class="BigBox">
        <!-- 星空背景 -->
        <div class="stars" ref="starsRef">
            <div v-for="(item,index) in statrsCount" :key="index" class="star">

            </div>
        </div>
        <div class="TopNav">
            <!-- 按钮 -->
            <img
                @click="GoHome"
                src="../../assets/ConstructionLandscape/Home.png"
                alt=""
            />
        </div>
        <!-- 登录/注册 -->
        <div class="Login">
            <div class="LoginConBox">
                <div class="LoginConTop">
                    <div>湘江段灵境体验</div>
                </div>
                <div class="LoginConInputBox">
                    <div class="UserNameico"></div>
                    <input
                        id="mobileinput"
                        placeholder="请输入账号"
                        v-model="LoginForm.mobile"
                        @blur="JyUserNull"
                        type="text"
                        :style="{'--yzcolor':yzcolor}"
                    />
                </div>
                <div class="LoginConInputBox" style="margin-top: 2vh">
                    <div class="PassWordico"></div>
                    <input
                        id="pasinput"
                        placeholder="请输入密码"
                        v-model="LoginForm.password"
                        @blur="JyPassNull"
                        type="password"
                        :style="{'--yzcolor':yzcolor}"
                    />
                </div>
                <!-- <div class="JiZhuMiMa">
                <div class="CheckKuang">
                    <img style="height:100%;width: 100%;" src="../../assets/Login/CheckBoxTrue.png" alt="">
                </div>
                <div>记住密码</div>
            </div> -->
                <div class="LoginConAnNiu" @click="postLogin">
                    登陆
                </div>
                <div class="LoginConBottom">
                    <div style="display: flex; margin-bottom: 2vh">
                        没有账号？
                        <div @click="GoRegister()" style="text-decoration: underline; cursor: pointer;color:rgb(4,162,239)">
                            立即注册
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 悬浮图标 -->
        <div class="LeftSuspensionBox">
            <div class="LeftSuspensionBoxTop">
                <div class="container">
                    <div class="wrap">
                        <div class="cube">
                            <div class="out-front">
                                <!-- <img src="../../assets/Login/LoginQiuOne.png" alt=""> -->
                            </div>
                            <div class="out-back">
                                <!-- <img src="../../assets/Login/LoginQiuTwo.png" alt=""> -->
                            </div>
                            <div class="out-left">
                                <!-- <img src="../../assets/Login/LoginQiuthere.png" alt=""> -->
                            </div>
                            <div class="out-right">
                                <!-- <img src="../../assets/Login/LoginQiuFour.png" alt=""> -->
                            </div>
                            <div class="out-top">
                                <!-- <img src="../../assets/Login/LoginQiuFive.png" alt=""> -->
                            </div>
                            <div class="out-bottom">
                                <!-- <img src="../../assets/Login/LoginQiuSix.png" alt=""> -->
                            </div>

                            <span class="in-front">
                                <img
                                    src="../../assets/Login/LoginQiuOneNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-back">
                                <img
                                    src="../../assets/Login/LoginQiuTwoNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-left">
                                <img
                                    src="../../assets/Login/LoginQiuThereNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-right">
                                <img
                                    src="../../assets/Login/LoginQiuFourNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-top">
                                <img
                                    src="../../assets/Login/LoginQiuFiveNei.png"
                                    alt=""
                                />
                            </span>
                            <span class="in-bottom">
                                <img
                                    src="../../assets/Login/LoginQiuSixNei.png"
                                    alt=""
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="LeftSuspensionBoxMiddle"></div>
            <div class="LeftSuspensionBoxButtom"></div>
        </div>
        <!-- <div class="JiaoYanUser">
            <div style="height: 5vh">
                <div v-if="UserNoNull">账号不能为空</div>
                <div v-if="UserNoRules">请检查账号</div>
            </div>
            <div style="height: 5vh; display: flex; align-items: center">
                <div v-if="PasswordNoNull">密码不能为空</div>
                <div v-if="PasswordSix">密码不低于六位数</div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { log } from 'console'
export default {
    data() {
        return {
            yzcolor:'134,171,249',
            // 账号不为空
            UserNoNull: false,
            // 账号不通过校验
            UserNoRules: false,
            // 密码不为空
            PasswordNoNull: false,
            // 密码不低于六位数
            PasswordSix: false,
            Uuid: '',
            LoginForm: {
                // 手机号
                mobile: '',
                // 密码
                password: '',
            },
            statrsCount: 1000,//星星数量
            distance: 600,//间距
        }
    },
    created() {},
    mounted() {
        this.XingKo()
    },
    methods: {
        XingKo () {
            let starNodes = Array.from(this.$refs.starsRef.children);
            starNodes.forEach((item) => {
                let speed = 0.2 + Math.random() * 1;
                let thisDistance = this.distance + Math.random() * 300;
                item.style.transformOrigin = `0 0 ${thisDistance}px`;
                item.style.transform =
                    `
                translate3d(0,0,-${thisDistance}px)
                rotateY(${Math.random() * -360}deg)
                rotateX(${Math.random() * -30}deg)
                scale(${speed},${speed})`;
            });
        },
        // 返回首页
        GoHome() {
            this.$router.push('/')
            // window.location.reload();
        },
        // 校验账号
        JyUserNull() {
            if (this.LoginForm.mobile == '') {
                document.getElementById("mobileinput").setAttribute("placeholder", "账号不能为空")
                this.yzcolor = '#00f5ff'
            } else {
                document.getElementById("mobileinput").setAttribute("placeholder", "请输入账号")
                this.yzcolor = '134,171,249'
            }
        },
        // 校验密码
        JyPassNull() {
            if (this.LoginForm.password == '') {
                document.getElementById("pasinput").setAttribute("placeholder", "密码不能为空")
                this.yzcolor = '#00f5ff'
            } else {
                document.getElementById("pasinput").setAttribute("placeholder", "请输入密码")
                this.yzcolor = '134,171,249'
            }
        },
        // 登陆逻辑
        // 登录校验
        postLogin() {
            // 校验登陆账号
            if (this.LoginForm.mobile == '') {
                document.getElementById("mobileinput").setAttribute("placeholder", "账号不能为空")
            } else {
                this.UserNoNull = false
                let reg = /[^\w]/g
                if (!reg.test(this.LoginForm.mobile)) {
                    this.UserNoRules = false
                } else {
                    this.UserNoRules = true
                }
            }
            // 校验密码
            if (this.LoginForm.password == '') {
                this.PasswordNoNull = true
            } else {
                this.PasswordNoNull = false
                let reg = /^[0-9a-zA-Z]{6,16}$/
                if (reg.test(this.LoginForm.password)) {
                    this.PasswordSix = false
                } else {
                    this.PasswordSix = true
                }
            }
            if (
                !this.UserNoNull &&
                !this.UserNoRules &&
                !this.PasswordNoNull &&
                !this.PasswordSix
            ) {
                this.SubLogin()
            } else {
            }
        },
        // 调取登陆接口
        SubLogin() {
            var axios = require('axios')
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/auth/login',
                headers: {
                    token: '',
                },
                data: {
                    mobile: this.LoginForm.mobile,
                    password: this.LoginForm.password,
                },
            }
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        // 把token存放在cookies里
                        this.$cookies.set('token', res.data.data.token, '7d')
                        this.$router.push('/personalCenter')
                    } else {
                        alert(res.data.msg)
                    }
                })
                .catch(function (error) {})
        },
        // 登陆逻辑结束
        GoRegister() {
            this.$router.push('/register')
        },
    },
}
</script>

<style lang="less" scoped>
input::placeholder {
    color: rgb(var(--yzcolor));
}

:-ms-input-placeholder {
    color: rgb(var(--yzcolor));
}

::-webkit-input-placeholder {
    color: rgb(var(--yzcolor));
}
* {
    margin: 0;
    padding: 0;
}

.JiaoYanUser {
    height: 20vh;
    width: 18vh;
    position: relative;
    left: 82%;
    top: 21vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.JiaoYanPassword {
    position: relative;
    left: 173vh;
    top: 43vh;
}
input {
    background: none;
    outline: none;
    border: none;
}
    .stars {
        transform: perspective(500px);
        transform-style: preserve-3d;
        position: absolute;
        width: 100%;
        height: 100%;
        perspective-origin: 50% 100%;
        animation: rotate 90s infinite linear;
        top: 80%;
        z-index: 1;
    }
    .star {
        width: 0.3vh;
        height: 0.3vh;
        background: #f7f7b8;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
    }
    @keyframes rotate {
        0% {
            transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
                rotateY(0);
        }
        100% {
            transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
                rotateY(-360deg);
        }
    }
.BigBox {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: radial-gradient(
        200% 100% at bottom center,
        #101929,
        #000000,
        #1b2947
    );
    // background: radial-gradient(200% 105% at top center,#013990 10%,#000000 40%,#013990 65%,#1b2947);
    background-attachment: fixed;
    color: rgb(0, 245, 255);
    .TopNav {
        width: 100%;
        height: 10%;
        z-index: 99;
        img {
            height: 85%;
            margin-left: 10vh;
            margin-top: 1vh;
            cursor: pointer;
        }
    }
    .Img {
        // position: fixed;
        right: 0px;
        bottom: 0px;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
        source {
            min-width: 100%;
            min-height: 100%;
            height: auto;
            width: auto;
        }
    }
    // 登录/注册
    .Login {
        position: absolute;
        top: 18%;
        left: 50%;
        width: 45%;
        height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        .LoginConBox {
            width: 66%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-image: url(../../assets/Login/LoginBoxBG.png);
            background-size: 100% 100%;
            padding: 2vh 0;
            .LoginConTop {
                width: 100%;
                height: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3vh;
                color: white;
            }
            .LoginConInputBox {
                width: 70%;
                height: 7vh;
                margin: 1vh 0;
                background-color: rgb(16,62,121);
                display: flex;
                align-items: center;
                border: 1px solid rgb(3,113,203);
                border-radius: 5px;
                .UserNameico {
                    height: 50%;
                    width: 7%;
                    background-image: url(../../assets/Login/UserName.png);
                    background-size: 100% 100%;
                    margin-left: 3vh;
                }
                .PassWordico {
                    height: 50%;
                    width: 7%;
                    background-image: url(../../assets/Login/PassWord.png);
                    background-size: 100% 100%;
                    margin-left: 3vh;
                }
                input {
                    background: none;
                    outline: none;
                    -webkit-appearance: none;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    margin-left: 1vh;
                    color: rgb(var(--yzcolor));
                    font-size: 2vh;
                }
                input:focus {
                    border: none;
                }
            }
            .JiZhuMiMa {
                display: flex;
                align-items: center;
                position: relative;
                left: -24%;
                .CheckKuang {
                    width: 2vh;
                    height: 2vh;
                    cursor: pointer;
                    margin: 0 0.5vh;
                }
            }
            .LoginConAnNiu {
                width: 70%;
                height: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 3vh;
                cursor: pointer;
                color: white;
                border-radius: 5px;
                background: linear-gradient(to right, rgb(1, 128, 235), rgb(2, 175, 251));
            }
            // .LoginConAnNiu:hover {
            //     transform: scale(1.05);
            // }
            .LoginConBottom {
                width: 70%;
                height: 15%;
                // background-image: url(../../assets/Login/LoginConButtom.png);
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                font-size: 1.8vh;
                color: rgb(134,171,249);
            }
        }
    }
    .LeftSuspensionBox {
        position: absolute;
        top: 17%;
        left: 8%;
        width: 35%;
        height: 80vh;
        .LeftSuspensionBoxTop {
            width: 55%;
            height: 35%;
            position: relative;
            top: 0%;
            left: 22%;
            z-index: 1;
            animation: LeftSuspensionBoxTop 3s infinite; // 反反向再运行一遍
            animation-direction: alternate;
            /* 全模块定位和总布局 用定位top/left,没有margin但不脱标调位置 */

            .container {
                position: relative;
                top: -5vh;
                left: 15vh;
            }
            /* 主要用来装功能块在容器内保证定位 相当于该功能块的padding*/

            .container .wrap {
                width: 10vh;
                height: 10vh;
                top: 1vh;
                left: 1vh;
                margin: 0;
            }
            /* 功能块 */

            .container .wrap .cube {
                width: 10vh;
                height: 10vh;
                transform-style: preserve-3d;
                /* perspective: 500px; */
                transform: rotateX(-30deg) rotateY(-80deg);
                animation: ziZhuan linear 20s infinite;
                animation-direction: alternate-reverse;
                transition: all 0.5s;
            }

            @keyframes ziZhuan {
                0% {
                    transform: rotateX(0deg) rotateY(0deg);
                }
                100% {
                    transform: rotateX(360deg) rotateY(360deg);
                }
            }

            .container .wrap .cube div {
                position: absolute;
                top: 0;
                left: 0;
                width: 20vh;
                height: 20vh;
                background-color: rgb(0, 183, 255);
                z-index: -1;
                transition: all 0.5s;
                opacity: 0.45;
                outline: rgb(40, 226, 240) solid thick;
                box-shadow: rgba(17, 123, 194, 0.712) 0 0 15px 15px;
            }

            .container .wrap .cube div img {
                width: 100%;
                height: 100%;
            }

            .container .wrap .cube span {
                position: absolute;
                top: 5vh;
                left: 5vh;
                display: block;
                width: 10vh;
                height: 10vh;
                outline: rgba(21, 40, 211, 0.664) solid thin;
                background-color: rgba(11, 96, 224, 0.295);
            }

            .container .wrap .cube span img {
                width: 100%;
                height: 100%;
            }

            .container .wrap .cube .out-front {
                transform: rotateY(0deg) translateZ(10vh);
            }

            .container .wrap .cube .out-back {
                transform: translateZ(-10vh);
            }
            /* //y正方向逆时针 */
            .container .wrap .cube .out-left {
                transform: rotateY(-90deg) translateZ(10vh);
            }

            .container .wrap .cube .out-right {
                transform: rotateY(90deg) translateZ(10vh);
            }
            /*  x->正-上*/

            .container .wrap .cube .out-top {
                transform: rotateX(90deg) translateZ(10vh);
            }

            .container .wrap .cube .out-bottom {
                background-color: rgba(36, 238, 80, 0.253);
                transform: rotateX(-90deg) translateZ(10vh);
            }
            .container .wrap .cube .in-left {
                transform: rotateY(-90deg) translateZ(5vh);
            }
            .container .wrap .cube .in-right {
                transform: rotateY(90deg) translateZ(5vh);
            }
            .container .wrap .cube .in-back {
                transform: translateZ(-5vh);
            }
            .container .wrap .cube .in-front {
                transform: translateZ(5vh);
            }
            .container .wrap .cube .in-top {
                transform: rotateX(90deg) translateZ(5vh);
            }
            .container .wrap .cube .in-bottom {
                transform: rotateX(-90deg) translateZ(5vh);
            }
            .container .wrap .cube:hover .out-front {
                transform: rotateY(0deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-back {
                transform: rotateY(0deg) translateZ(-15vh);
            }
            .container .wrap .cube:hover .out-top {
                transform: rotateX(90deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-bottom {
                transform: rotateX(-90deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-right {
                transform: rotateY(90deg) translateZ(15vh);
            }
            .container .wrap .cube:hover .out-left {
                transform: rotateY(-90deg) translateZ(15vh);
            }
        }
        @keyframes LeftSuspensionBoxTop {
            0% {
                top: 0%;
            }
            100% {
                top: 8%;
            }
        }
        .LeftSuspensionBoxMiddle {
            width: 100%;
            height: 90%;
            position: relative;
            top: -30%;
            background-image: url(../../assets/Login/LeftSuspensionBoxMiddle.png);
            background-size: 100% 100%;
            animation: LeftSuspensionBoxMiddle 3s infinite; // 反反向再运行一遍
            animation-direction: alternate;
        }
        @keyframes LeftSuspensionBoxMiddle {
            0% {
                top: -35%;
            }
            100% {
                top: -30%;
            }
        }
        .LeftSuspensionBoxButtom {
            width: 100%;
            height: 20vh;
            position: relative;
            top: -42%;
            left: 12%;
            background-image: url(../../assets/Login/LeftSuspensionBoxButtom.png);
            background-size: 100% 100%;
        }
    }
}
</style>